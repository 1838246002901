@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: $white;
	color: $black;

	text-align: center;

	position: relative;

	padding: 6rem 0 0;

	> * {
		padding: 4rem 2rem;
		max-width: 90rem;
		margin-inline: auto;
	}

	isolation: isolate;
}

.container header {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	gap: 2rem;

	padding: 2rem 2rem 6rem;

	h2 {
		@include headline3;

		opacity: 0; // Overwritten by GSAP
		max-width: 22ch;
	}

	p {
		@include eyebrow1;
		opacity: 0; // Overwritten by GSAP

		@include sm-down {
			@include eyebrow2;
		}
	}

	@include sm-down {
		h2 {
			@include headline5;
		}
	}
}

.base_section,
.cools_section,
.elevates_section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	overflow: hidden;

	.media_container {
		transform: translateX(-2%);
	}

	@include sm-down {
		padding: 6rem 2rem;
		gap: 0;
		.media_container {
			scale: 1.2;
			transform: translateX(-3%);
		}
	}
}

.media_container {
	position: relative;

	z-index: -1;
	width: 100%;
	height: clamp(30rem, 30vw, 50rem);

	img,
	video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;

		opacity: 0;
	}

	@include sm-down {
		height: clamp(15rem, 40vw, 30rem);
	}
}

.basic_big_text {
	@include headline3;

	transform: translateY(1ch);

	@include sm-down {
		transform: translateY(-25%);
		@include headline5;
	}

	span {
		opacity: 0; // Overwritten by GSAP
		display: inline-block;
		margin-inline: 0.15ch;
	}
}

.automatically_text {
	@include regular;

	line-height: 1;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	justify-content: center;
	align-items: center;

	padding: 0.15em;
	margin-top: -0.09em;

	@include sm-down {
		margin-top: -0.085em;
	}

	background: linear-gradient(88deg, #6c69ff 26.68%, #c260ff 72.02%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;

	//mix-blend-mode: difference;

	opacity: 0;
}

.automatically_spacer {
	height: 100svh;

	padding: 0;
	max-width: unset;
	width: 100%;
}

.automatically_static_content {
	display: flex;
	flex-direction: column;
	position: absolute;

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	justify-content: center;
	align-items: center;
	gap: 2rem;
}

.automatically_section {
	padding: 0 !important;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-width: unset;

	pointer-events: none;
}

.automatically_cart {
	position: sticky;
	top: 0;

	height: 100svh;
	width: 100%;

	canvas {
		width: 100%;
		height: 100%;

		position: absolute;
		top: 0;
		left: 0;

		//transform: translateY(-2.3%);
	}
}

.automatically_cta {
	border-radius: 0.5rem;
	background: #f2f2f2 !important;

	position: relative;
	z-index: 2;

	padding: 1rem 1.125rem !important;

	pointer-events: none;

	opacity: 0;

	span {
		@include headline8;

		display: inline-flex;
		align-items: center;
		gap: 0.875rem;
	}

	&:hover,
	&:focus-visible {
		background: #d9d9d9 !important;
		scale: 1.01;
	}

	&:active {
		scale: 0.99;

		opacity: 0.8;
	}
}
