@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: #141414;
	color: $white;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	padding: 10rem 2rem;

	position: relative;

	isolation: isolate;

	@include sm-down {
		padding: 6rem 2rem;
	}
}

.container header {
	p {
		@include eyebrow1;

		margin-bottom: 0.5rem;
	}

	h2 {
		@include headline3;
	}

	margin-bottom: 4rem;

	@include sm-down {
		h2 {
			@include headline5;

			max-width: 12ch;
			margin-inline: auto;
		}
	}
}

.moire_container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	overflow: hidden;
}

.moire_container img {
	opacity: 0.2;

	&:first-child {
		position: absolute;
		top: 20%;
		right: -5rem;

		height: 14rem;
	}

	&:last-child {
		position: absolute;
		bottom: -5rem;
		left: 0;

		height: 14rem;
	}

	@include sm-down {
		&:first-child {
			top: 10%;
			right: -15rem;
		}

		&:last-child {
			bottom: 10rem;
			left: -5rem;
		}
	}
}

.leader_list {
	list-style: none;

	display: grid;

	grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
	max-width: 85rem;

	gap: 4rem;

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.leader {
	display: flex;

	flex-direction: column;

	text-align: left;
}

.leader img {
	max-width: 30rem;
	border-radius: 0.5rem;

	margin-bottom: 2rem;

	aspect-ratio: 375 / 300;
}

.leader h3 {
	@include headline6;
	margin-bottom: 0.25rem;

	@include sm-down {
		@include headline7;
		margin-bottom: 0.25rem;
	}
}

.leader p {
	@include body2;

	opacity: 0.65;
}
