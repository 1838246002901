@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';
@import 'styles/mixins/fonts-decorated';

.container {
	background-color: #141414;
	color: $white;
	display: flex;
	flex-direction: column;

	gap: 3rem;
	justify-content: center;

	padding: $spacing-xl $spacing-md;

	@include sm-down {
		padding: $spacing-lg $spacing-md;
	}
}

.pill {
	@include blue_tag;
}

.title {
	@include headline3;

	@include sm-down {
		@include headline5;
	}
	text-align: center;
	line-height: 110%;
	font-weight: 400;
}

.description {
	@include body2;
	@include sm-down {
		@include body3;
	}
	max-width: 55ch;
	color: #717171;
	text-align: center;
}

.tabs {
	display: flex;
	flex-direction: row;
	gap: 2.5rem;

	margin-top: 4rem;

	@include sm-down {
		gap: 0;
		flex-direction: column;
		margin-top: 2rem;
	}

	justify-content: center;

	> button > span {
		width: 100%;
	}
}

.tab {
	@include body1;
	position: relative;
	margin-top: -1px;
	border-top: 1px solid rgba(217, 217, 217, 0.2);
	padding: 1.5rem 0;

	display: block;

	color: $white;

	//cursor: pointer;
	width: 190px;

	text-align: left;

	transition: all 0.25s ease-out;

	@include sm-down {
		@include body2;
		padding-top: 1rem;
		padding-bottom: 2rem;
		width: 70%;
	}

	z-index: 0;
}

.tab_progress {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;

	width: 100%;
	height: 1px;
	background-color: $white;

	transition: opacity 0.25s ease-out;
	z-index: 1;

	opacity: 0;
}

.tabSelected {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 60%;
	margin-top: -1px;
	border-top: 1px solid $white;
	z-index: 1;
}

.tabContainer {
	max-width: 60rem;

	margin-inline: auto;

	width: 100%;
}

.media_container {
	position: relative;

	width: 100%;
}

.slide {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.slide svg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	z-index: 1;

	opacity: 0;

	transition: all 0.25s ease-out;
}

.slide_selected svg {
	opacity: 1 !important;
}

.video_container {
	position: relative;

	max-height: 40rem;
	height: 40vw;
	width: 100%;

	margin-bottom: 4rem;

	@include sm-down {
		height: 22rem;
		margin-bottom: 2rem;
	}
}

.media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	object-fit: cover;

	border-radius: $br-md;

	video {
		border-radius: $br-md;

		object-fit: cover;
		@include sm-down {
			aspect-ratio: 1;
			min-height: 15rem;

			max-height: 30rem;
		}
	}
}

.pitch {
	display: flex;
	gap: 1.5rem;
	flex-direction: column;
	align-items: center;

	@include sm-down {
		gap: 0.75rem;
	}
}
